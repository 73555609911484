import {
  DEFAULT_TIMEOUT,
  DEFAULT_TRIES,
  MAX_TIMEOUT,
  TIMEOUT_INTERVAL
} from '@/constants/APIConstants'
import { AxiosResponse } from 'axios'

export const incrementalTimeoutHandler = async (
  call: (timeout: number) => Promise<AxiosResponse>,
  tries = DEFAULT_TRIES
): Promise<AxiosResponse> => {
  //On invalid tries, use default tries constant
  tries = tries > 0 ? tries : DEFAULT_TRIES

  let i = 0
  let result = undefined
  let timeout = DEFAULT_TIMEOUT
  do {
    try {
      const response = await call(timeout)

      //Check for timeout error and try again with different timeout if present.
      if (response && response.status === 408) {
        //Increase the timeout (up to max timeout) and try again.
        timeout = Math.min(timeout + TIMEOUT_INTERVAL, MAX_TIMEOUT)
        console.log('Call timed out. Trying again with timeout: ' + timeout)

        i++
        continue
      }

      //If the response was good, return it and exit.
      result = response
      //If we encounter an error, try again.
    } catch (error) {
      console.log(error)
      timeout = Math.min(timeout + TIMEOUT_INTERVAL, MAX_TIMEOUT)
      i++
      continue
    }

    break
  } while (i < tries)

  //If no result was returned, give a timeout error.
  if (!result) {
    return { status: 408 } as AxiosResponse
  }

  return result
}
