import BestDeals from '@/components/bestDeals/BestDeals'
import JobList from '@/components/jobs/JobList'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handleEcosystemModule = (id: string) => {
  switch (id) {
    case 'module_ecosystem_approved_suppliers':
      return <BestDeals />
    case 'module_ecosystem_jobs':
      return <JobList />
    default:
      logSentryException('handleEcosystemModule no supported module', 'MODULE', id)
  }
}
