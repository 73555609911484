import { getSuppliersCategoriesFilter } from '@/constants/FieldsApprovedSuppliers'
import { DEFAULT_LIST_SIZE } from '@/constants/RequestConstants'
import { FRONT_GET_SUPPLIERS } from '@/constants/Routes'
import useQueryFilters from '@/hooks/useQueryFilters'
import { FilterItem } from '@/models/EudonetModels'
import { ApprovedSupplier } from '@/models/company/ApprovedSupplier'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { useOnEnterViewport } from '@/utils/useIsInViewport'
import { AxiosError, isAxiosError } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import ListFetchingHandling from '../shared/dataFetching/ListFetchingHandling'
import FilterSection, { FilterType } from '../shared/filters/FilterSection'
import BestDealItem from './BestDealsItem'

const filters: FilterType[] = [{ name: 'Categories', filterList: getSuppliersCategoriesFilter() }]

const BestDeals = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([])
  const [queryFilters, addQueryFilter, removeQueryFilter] = useQueryFilters('')
  const [queryText, setQueryText] = useState<string>('')
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [canLoadMore, setCanLoadMore] = useState(true)
  const [data, setData] = useState<ApprovedSupplier[]>([])
  const [error, setError] = useState<AxiosError>()
  const [items, setItems] = useState(0)
  const [initialized, setInitialized] = useState(false)
  const call = useCallback(
    async (reset: boolean) => {
      try {
        const next = await baseFetcher<{ data: ApprovedSupplier[]; items: number }>(
          FRONT_GET_SUPPLIERS(DEFAULT_LIST_SIZE, currentPage, queryFilters, queryText)
        )
        setCurrentPage(reset ? 1 : currentPage + 1)

        setData(data =>
          reset
            ? next.data
            : [...data.filter(d => !next.data.find(nd => nd.id === d.id)), ...next.data]
        )
        if (data.length + next.data.length >= next.items) {
          debugger
          setCanLoadMore(false)
        }
        setItems(is => (reset ? next.items : is + next.items))
      } catch (e) {
        if (isAxiosError(e)) setError(e)
      }
    },
    [currentPage, queryFilters, queryText, data]
  )

  useEffect(() => {
    call(true)
  }, [queryFilters, queryText])

  useEffect(() => {
    setInitialized(false)

    call(false).finally(() => setTimeout(() => setInitialized(true), 300))
  }, [])

  const isLoadingMore = useOnEnterViewport(
    loadMoreRef,
    async () => {
      debugger
      if (initialized && canLoadMore) {
        await call(false)
      }
    },
    [call, initialized, canLoadMore]
  )

  const addFilter = async (item: FilterItem) => {
    if (!data) {
      return
    }

    if (selectedFilters.some(temp => temp.Id === item.Id)) {
      return
    }
    setCurrentPage(1)
    setSelectedFilters([...selectedFilters, item])

    addQueryFilter(selectedFilters, item)
  }

  const removeFilter = (item: FilterItem) => {
    setCurrentPage(1)
    setSelectedFilters(selectedFilters.filter(filter => item.Id !== filter.Id))

    removeQueryFilter(selectedFilters, item)
  }

  return (
    <>
      <FilterSection
        filterTypes={filters}
        selectedFilters={selectedFilters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        searchText={setQueryText}
      />

      {data && data.length > 0 && (
        <>
          <div className='section wf-section margin-bottom-4x'>
            <div className='wrapper'>
              <div className='table-grid'>
                {data.map(supplier => (
                  <BestDealItem supplier={supplier} key={supplier.id} />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <ListFetchingHandling data={{ data, items }} error={error} forceFetching={isLoadingMore} />
      <div ref={loadMoreRef}></div>
    </>
  )
}

export default BestDeals
