import { ApprovedSupplier } from '@/models/company/ApprovedSupplier'
import styles from '@/styles/tableItem.module.css'
import Image from 'next/image'
import { useState } from 'react'
import InjectHtml from '../shared/InjectHtml'

interface Props {
  supplier: ApprovedSupplier
}

const BestDealItem = ({ supplier }: Props) => {
  const [showMore, setShowMore] = useState<boolean>(false)

  return (
    <>
      <div className='table-grid-row'>
        <div className='table-column vertical'>
          <div>
            <div className='table-item-text bold'>
              <strong>{supplier.name}</strong>
              {supplier.isSustainable && (
                <Image
                  src='/icons/sustainable.png'
                  alt='Sustainable'
                  className='margin-left-050'
                  width={24}
                  height={24}
                  style={{ width: '24px', height: '24px' }}
                />
              )}
            </div>
          </div>
          <div className='color-primary'>
            {supplier.category && (
              <div className='tag outlined small fill-primary margin-right-050'>
                {supplier.category}
              </div>
            )}
            {supplier.service && supplier.service !== '-' && (
              <div className='tag outlined small'>{supplier.service}</div>
            )}
          </div>
        </div>

        <div className='table-column'>
          {supplier.website && (
            <>
              <div className='fa-l small'></div>
              <a
                href={supplier.website}
                target='_blank'
                rel='noreferrer noopener'
                className='table-item-text'>
                {supplier.website}
              </a>
            </>
          )}
        </div>
        <div className='table-column vertical'>
          <div className='table-column'>
            {supplier.contactEmail && (
              <>
                <div className='fa-l small'></div>
                <a
                  href={`mailto:${supplier.contactEmail}`}
                  target='_blank'
                  rel='noreferrer noopener'
                  className='table-item-text'>
                  {supplier.contactEmail}
                </a>
              </>
            )}
          </div>
          <div className='table-column'>
            {supplier.contactPhone && (
              <>
                <div className='fa-l small'></div>
                <a
                  href={`tel:${supplier.contactPhone}`}
                  target='_blank'
                  rel='noreferrer noopener'
                  className='table-item-text'>
                  {supplier.contactPhone}
                </a>
              </>
            )}
          </div>
        </div>
        <div className='table-column'>
          {supplier.contactName && (
            <>
              <div className='fa-l small'></div>
              <div className='table-item-text'>{supplier.contactName}</div>
            </>
          )}
        </div>
        {supplier.moreInfo && (
          <div className='table-column align-right'>
            <a
              href='#'
              className='button small icon button-dark w-inline-block'
              onClick={() => setShowMore(val => !val)}>
              <div className='button-icon-content'>
                <div className='button-icon-text'>More info</div>
                <div className='button-icon small'></div>
              </div>
            </a>
          </div>
        )}
        {showMore && (
          <div className={styles.details}>
            <InjectHtml data={supplier.moreInfo} wrapInRichText={false} />
          </div>
        )}
      </div>
    </>
  )
}

export default BestDealItem
